<script setup>
import {ref, watch} from 'vue';
import {useHead, useNuxtApp, useRoute} from '#imports';
// import useSettings from '~/composables/use-settings.js';
import {NuxtPage, NuxtRouteAnnouncer, NuxtLoadingIndicator} from '#components';
// import IndexPage from '~/pages-disabled/index.vue';
import { ModalsContainer } from 'vue-final-modal';
import { Toaster } from 'vue-sonner';

const route = useRoute();

// const {isDarkMode} = useSettings();

useHead(() => {
    return {
        htmlAttrs: {
            class: route.name.startsWith('wallet') ? 'theme--dark' : 'theme--blue',
        },
    };
});

watch(() => route.name, async () => {
    const webApp = await window.getTelegramWebApp();
    if (route.name.startsWith('wallet')) {
        webApp.setHeaderColor('#2b2b2b');
    } else {
        webApp.setHeaderColor('#1f4ca4');
    }
}, {
    immediate: true,
});

// const isLoading = ref(true);
//
// const nuxtApp = useNuxtApp();
// nuxtApp.hook('app:suspense:resolve', (event) => {
//     console.log('page-finisn', event);
//     isLoading.value = false;
// });
</script>

<script>
export default {
    // mounted() {
    //     console.log('mounted')
    //     window.vm = this;
    // },
};
</script>

<template>
    <div class="layout--default tma-sticky-mobile-content">
        <NuxtLoadingIndicator/>
        <NuxtRouteAnnouncer />

        <NuxtPage/>
        <!--<IndexPage/>-->

        <ModalsContainer/>
        <Toaster theme="dark" :duration="3000"/>
    </div>
</template>

