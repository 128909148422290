import { defineNuxtPlugin } from '#app';
import hapticFeedback from '~/directives/v-haptic-feedback.ts';
import {BASE_URL_PREFIX} from '~/config.js';


export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.mixin({
        computed: {
            VITE_ENV: () => import.meta.env.VITE_ENV,
            BASE_URL_PREFIX: () => BASE_URL_PREFIX,
            // DASHBOARD_URL: () => DASHBOARD_URL,
        },
        methods: {
            $td(text, slug) {
                return text;
            },
        },
    });

    nuxtApp.vueApp.directive('hapticFeedback', hapticFeedback);
});

// export default ({ app}) => {
//     app.i18nGetPreferredPath = app.localePath;
// };
