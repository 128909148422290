import { defineStore } from 'pinia';

/** @import {WebAppUser} from 'telegram-web-app'  */
/** @import {GemBotUserResponse} from '~/api/tg-bot-models.d.ts'  */

export const useMainStore = defineStore('main', {
    state: () => ({
        /** @type {GemBotUserResponse}*/
        user: undefined,
        /** @type {WebAppUser} */
        tgUser: undefined,
        /** @type {number|string} */
        gemBalance: 0,
        referralsCount: 0,
    }),
    getters: {
        /**
         * Checks if user is authorized
         * @return {boolean}
         */
        isAuthorized(state) {
            return !!state.tgUser?.address;
        },
        /** @return {string} */
        address(state) {
            return state.tgUser?.address;
        },
        username(state) {
            return state.tgUser ? [state.tgUser.first_name, state.tgUser.last_name].filter((i) => !!i).join(' ') : '';
        },
    },
    actions: {
        LOGOUT() {
            this.tgUser = undefined;
            this.user = undefined;
            // clear data
            this.referralsCount = 0;
            this.gemBalance = 0;
        },
        /**
         * @param {WebAppUser} tgUser
         * @param {GemBotUserResponse} user
         * @param {number} referralsCount
         */
        AUTHORIZE(tgUser, user, referralsCount) {
            this.LOGOUT();
            this.tgUser = tgUser;
            this.user = user;
            this.referralsCount = referralsCount;
        },
        /**
         * @param {number|string} balance
         */
        SET_GEM_BALANCE(balance) {
            this.user = {
                ...this.user,
                balance,
            };
        },
        SET_TERMS(value) {
            this.user = {
                ...this.user,
                termsAccepted: value,
            };
        },
    },
});


