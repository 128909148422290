import {ref} from 'vue';
import {defineNuxtPlugin} from '#app';
import {getMe, getReferrals} from '~/api/tg-bot.js';
import {useMainStore} from '~/store/index.js';

export default defineNuxtPlugin({
    dependsOn: ['telegram-web-app'],
    setup(nuxtApp) {
        const store = useMainStore();

        async function authorizeUser() {
            const user = await getMe();
            const {count} = await getReferrals();
            const webApp = await window.getTelegramWebApp();
            window.authorizationStatus.value = 'success';

            return store.AUTHORIZE(webApp.initDataUnsafe.user, user, count);
        }

        // don't wait in plugin but allow components to wait for auth if needed
        window.authorizationPromise = authorizeUser();
        window.authorizationStatus = ref();
        window.authorizationStatus.value = 'pending';

        // eliminate unhandled promise rejection
        window.authorizationPromise.catch((error) => {
            console.error('Failed to authorize user', error);
            window.authorizationStatus.value = 'error';
        });
    },
});
