/// <reference types="telegram-web-app" />
import type {ObjectDirective, FunctionDirective} from 'vue';

type ImpactType = Parameters<Telegram['WebApp']['HapticFeedback']['impactOccurred']>[0];

const hapticFeedback: FunctionDirective<Element, ImpactType> = function mounted(el, binding, vnode) {
    const impactType: ImpactType = binding.value || 'medium';
    el.addEventListener('click', () => {
        window.Telegram?.WebApp?.HapticFeedback.impactOccurred(impactType);
    });
};

export default hapticFeedback;
