import {defineNuxtPlugin} from '#imports';

const GTAG_ID = 'G-VHG511D2KJ';

export default defineNuxtPlugin({
    parallel: true,
    setup() {
        /*
        ** Only run on client-side and only in production mode
        */
        if (process.env.NODE_ENV !== 'production') {
            return;
        }


        window['dataLayer'] = window['dataLayer'] || [];
        function gtag(){
            window['dataLayer'].push(arguments);
        }
        gtag('js', new Date());
        gtag('config', GTAG_ID);

        /*
        ** Include GTM Script
        */
        let script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GTAG_ID;
        document.body.appendChild(script);


        // app.router.afterEach((to, from) => {
        //     /*
        //     ** We tell Google Analytics to add a `pageview`
        //     */
        //     window['dataLayer'].push({ event: 'nuxtRoute', pageType: 'PageView', pageUrl: to.fullPath, routeName: to.name });
        // });
    },
});

