import {defineNuxtPlugin} from '#app';
import { createPinia, setActivePinia } from 'pinia';
import { useMainStore } from '~/store/index.js';

export default defineNuxtPlugin((nuxtApp) => {
    const pinia = createPinia();
    nuxtApp.vueApp.use(pinia);
    setActivePinia(pinia);

    if (import.meta.server) {
        nuxtApp.payload.pinia = pinia.state.value;
    } else if (nuxtApp.payload && nuxtApp.payload.pinia) {
        pinia.state.value = nuxtApp.payload.pinia;
    }

    const $store = useMainStore(nuxtApp.$pinia);

    // workaround https://youtrack.jetbrains.com/issue/WEB-56523/Nuxt-3-Plugins-are-unresolved-in-templates#focus=Comments-27-6798318.0-0
    // nuxtApp.vueApp.mixin({
    //     computed: {
    //         $store: () => $store,
    //     },
    // });

    return {
        provide: {
            store: $store,
        },
    };
});
