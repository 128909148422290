/// <reference path="./tg-bot-models.d.ts" />
/// <reference path="./tg-bot-types.d.ts" />

import axios from 'axios';
// import {cacheAdapterEnhancer, Cache} from 'axios-extensions';
import { wait } from '@shrpne/utils/src/wait.js';
// import {getDefaultAdapter} from '~/api/utils/axios-default-adapter.js';
import addToCamelInterceptor from '~/api/utils/axios-to-camel.js';
import {TG_AUTH_URL} from "~/config.js";
// import {toSnake} from '~/utils/snake-case.js';
import {TgBotTxStatus} from '~/api/tg-bot-types.d.ts';

const instance = axios.create({
    baseURL: TG_AUTH_URL,
    // adapter: cacheAdapterEnhancer(getDefaultAdapter(), {
    //     enabledByDefault: false,
    //     cacheKeyGenerator: authHeaderKeyGenerator,
    // }),
});
addToCamelInterceptor(instance);


/**
 * @return {Promise<string>}
 */
async function prepareInitData() {
    if (!window.isTWA) {
        throw new Error('Not a TWA');
    }
    const WebApp = await window.getTelegramWebApp();

    if (!WebApp.initDataUnsafe?.hash) {
        throw new Error('No data from Telegram Bot');
    }
    return WebApp.initData;
}


/**
 * @return {Promise<GemBotUserResponse>}
 */
export async function getMe() {
    const initData = await prepareInitData();

    return instance.get('user', {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @return {Promise<GemBotUserReferralsResponse>}
 */
export async function getReferrals() {
    const initData = await prepareInitData();

    return instance.get('user/referrals', {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @param {'country'|'global'|'premium'} type
 * @return {Promise<GemBotTopRefsCountryResponse>}
 */
export async function getTopReferrals(type) {
    const initData = await prepareInitData();

    return instance.get(`user/referrals/top/${type}`, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}


/**
 * @return {Promise<GemBotResponseBaseResponse>}
 */
export async function acceptTerms() {
    const initData = await prepareInitData();

    return instance.put('user/terms', {}, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @return {Promise<GemBotResponseBaseResponse>}
 */
export async function clamRefRewards() {
    const initData = await prepareInitData();

    return instance.post('user/balance/claim', {}, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @return {Promise<Array<GemBotOffersResponse>>}
 */
export async function getOffers() {
    const initData = await prepareInitData();

    return instance.get('user/offers', {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @param {Array<ExpGesture>} gestureList
 * @return {Promise<GemBotResponseBaseResponse>}
 */
export async function sendSequence(gestureList) {
    const initData = await prepareInitData();
    // remove 'event' field
    gestureList = gestureList.map((item) => {
        return {
            type: item.type,
            direction: item.direction,
            timestamp: item.timestamp,
        };
    });

    return instance.post('game/gestures', gestureList, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @return {Promise<GemBotRandomSequenceResponse>}
 */
export async function loadDynamicSequence() {
    const initData = await prepareInitData();

    return instance.get('game/sequence', {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @param {GemBotSendTxRequest} tx
 * @return {Promise<number>}
 */
export async function submitTx(tx) {
    const initData = await prepareInitData();

    return instance.post('user/wallet/transaction', tx, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data.txId;
        });
}

/**
 * @param {GemBotSendTxRequest} tx
 * @return {Promise<GemBotGetTxResponse>}
 */
export function submitAndWaitTx(tx) {
    if (tx.data) {
        tx.data = tx.data.replace(/^0x/, '');
    }
    return submitTx(tx)
        .then((id) => waitTx(id));
}

/**
 * @param {number|string} id
 * @return {Promise<GemBotGetTxResponse>}
 */
export async function getTxStatus(id) {
    const initData = await prepareInitData();

    return instance.get(`user/wallet/transaction/${id}`, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @param {number|string} id
 * @return {Promise<GemBotGetTxResponse>}
 */
function waitTx(id) {
    return getTxStatus(id)
        .then((tx) => {
            // @TODO Executed status not implemented yet, so just wait for tx hash
            if (tx.status === TgBotTxStatus.Executed || tx.status === TgBotTxStatus.Processing) {
                if (tx.hash.indexOf('0x') !== 0) {
                    tx.hash = '0x' + tx.hash;
                }
                return tx;
            }

            if (tx.status === TgBotTxStatus.Failed) {
                throw new Error(tx.log);
            }

            // reject
            // if (isUnsubscribed) {
            //     throw new Error('unsubscribed');
            // }

            return wait(3000).then(() => waitTx(id));
        });
}

/**
 * @param {number|string} id
 * @return {Promise<GemBotGiveawayInfoResponse>}
 */
export async function getGiveaway(id) {
    const initData = await prepareInitData();

    return instance.get(`giveaway/${id}`, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @param {number|string} id
 * @return {Promise<Array<GemBotListWinnersResponse>>}
 */
export async function getGiveawayWinners(id) {
    const initData = await prepareInitData();

    return instance.get(`giveaway/${id}/winners`, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @param {number|string} id
 * @return {Promise<void>}
 */
export async function joinGiveaway(id) {
    const initData = await prepareInitData();

    return instance.post(`giveaway/${id}/join`, {}, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * @param {number|string} id
 * @return {Promise<void>}
 */
export async function claimGiveaway(id) {
    const initData = await prepareInitData();

    return instance.post(`giveaway/${id}/claim`, {}, {
            headers: {
                Authorization: initData,
            },
        })
        .then((response) => {
            return response.data;
        });
}
