import 'core-js/actual/promise/with-resolvers.js';
import {defineNuxtPlugin, useRouter} from '#app';
import {TWA_SCRIPT_URL, TWA_SCRIPT_HASH} from '~/config.js';
// import {sendAddress as _sendAddress} from '~/api/telegram.js';

const {promise: promiseLoad, resolve: resolveLoad, reject: rejectLoad} = Promise.withResolvers();
window.getTelegramWebApp = () => promiseLoad;
promiseLoad.catch((error) => {
    // handle uncaught rejection
    console.log(error);
});

export default defineNuxtPlugin({
    name: 'telegram-web-app',
    setup(nuxtApp) {
        console.log('register mock TelegramGameProxy');
        window.TelegramGameProxy = {
            receiveEvent: () => {},
        };

        const savedHash = window.location.hash;
        const devWebAppInitData = import.meta.env.VITE_DEV_WEB_APP_INIT_DATA;
        const hasUrlWebAppInitData = window.location.hash.includes('tgWebAppData=');
        const isDevTWA = import.meta.env.MODE === 'development' && (devWebAppInitData || hasUrlWebAppInitData);
        if (isDevTWA && !hasUrlWebAppInitData) {
            window.location.hash = '#tgWebAppData=' + encodeURIComponent(devWebAppInitData);
        }
        const isTWA = !!window.parent?.TelegramWebviewProxy || isDevTWA;
        // const isIframe = window.parent != null && window !== window.parent && window.parent.location.hostname !== 'honee.app';
        console.log('isTWA', isTWA);
        if (!isTWA) {
            rejectLoad(new Error('Not a TWA'));
            return;
        }

        window.isTWA = true;

        const script = document.createElement('script');
        // script.async = true;
        // disable cross-origin check, because tg script doesn't provide CORS headers (anyway integrity checked by CSP meta)
        // script.integrity = TWA_SCRIPT_HASH;
        // script.crossOrigin = 'anonymous';
        script.src = TWA_SCRIPT_URL;
        script.onload = init;
        script.onerror = /** @type {function(any): void} */(rejectLoad);
        document.body.appendChild(script);

        // wait for script to load, because something (probably nuxt/vue internals) overwrite window.location.hash and prevent telegram to properly parse initData
        return promiseLoad
            // don't halt plugin execution on error
            .catch((error) => {
                console.log(error);
            });

        function init() {
            // something rewrite hash with decoded, restore encoded
            setTimeout(() => {
                // window.location.hash = savedHash;
                window.savedHash = savedHash;
            }, 1000);

            resolveLoad(window.Telegram.WebApp);

            window.Telegram.WebApp.expand();
            // set in app.vue to avoid flickering
            // window.Telegram.WebApp.setHeaderColor('#2b2b2b');
            window.Telegram.WebApp.disableVerticalSwipes();

            const webApp = window.Telegram.WebApp;

            // fix clip
            document.getElementById('__nuxt').style.height = 'calc(100vh + 1px)';
            setTimeout(() => {
                // remove bottom space after tma is expanded
                // document.documentElement.style.overflow = 'clip';
                document.getElementById('__nuxt').style.height = '';
                window.Telegram.WebApp.HapticFeedback.notificationOccurred('success');
            }, 1000);

            /*
            setTimeout(() => {
                // Some versions of Telegram don't need the classes above.
                if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(webApp.platform)) {
                    return;
                }

                // sticky app
                // https://docs.telegram-mini-apps.com/platform/sticky-app
                // https://stackoverflow.com/questions/77808636/telegram-web-app-collapse-on-bottom-swipe/78165929#78165929
                // https://stackoverflow.com/questions/76842573/a-bug-with-collapsing-when-scrolling-in-web-app-for-telegram-bot
                const overflow = 100;
                document.body.style.overflowY = 'hidden';
                document.body.style.marginTop = `${overflow}px`;
                document.body.style.height = window.innerHeight + overflow + "px";
                document.body.style.paddingBottom = `${overflow}px`;
                window.scrollTo(0, overflow);


                const scrollableEl = document.getElementById('__nuxt');
                let ts;
                const onTouchStart = (e) => {
                    ts = e.touches[0].clientY;
                };
                const onTouchMove = (e) => {
                    if (scrollableEl) {
                        const scroll = scrollableEl.scrollTop;
                        const te = e.changedTouches[0].clientY;
                        if (scroll <= 0 && ts < te) {
                            e.preventDefault();
                        }
                    } else {
                        e.preventDefault();
                    }
                };
                document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
                document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });


                // document.documentElement.classList.add('tma-sticky-mobile');

                // document.body.classList.add('tma-sticky-mobile-body');
                // document.getElementById('__nuxt').classList.add('tma-sticky-mobile-wrap');
                // // document.querySelector('[data-tma-sticky-content]').classList.add('tma-sticky-mobile-content');
            }, 1000);
            */


            const router = useRouter();
            let backPath;
            router.afterEach((to, from) => {
                console.log('to', to);
                if (to.name !== 'index' && to.name !== 'wallet') {
                    const customBackPath = to.matched[0]?.components.default.tmaBackButtonPath;
                    backPath = customBackPath || (to.name.startsWith('wallet-') ? '/wallet' : '/');
                    console.log('backPath', backPath);
                    webApp.BackButton.show();
                    webApp.BackButton.offClick(goBack);
                    webApp.BackButton.onClick(goBack);
                } else {
                    webApp.BackButton.offClick(goBack);
                    webApp.BackButton.hide();
                }
            });

            function goBack() {
                webApp.BackButton.offClick(goBack);
                webApp.BackButton.hide();
                if (router.options.history.state.position > 0) {
                    router.back();
                } else {
                    router.push(backPath);
                }
            }
        }
    },
});

