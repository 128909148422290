import {defineNuxtPlugin} from '#app';
import * as Sentry from "@sentry/vue";
import { httpClientIntegration } from "@sentry/integrations";

console.log('sentry init', process.env.NODE_ENV)

export default defineNuxtPlugin((nuxtApp) => {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }
    Sentry.setTag("host", "app.gemchain.org");

    Sentry.init({
        dsn: "https://8b73b1b06c8a98882d9089b2757c4077@o4506586759364608.ingest.sentry.io/4506586763952128",
        app: nuxtApp.vueApp,
        environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
        integrations: [
            Sentry.browserTracingIntegration({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [/*"localhost", *//^https:\/\/app\.gemchain\.org/],
            }),
            // new Sentry.Replay({
            //     maskAllText: false,
            //     blockAllMedia: false,
            // }),

            httpClientIntegration(),
        ],
        normalizeDepth: 10,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.


        // This option is required for capturing headers and cookies.
        sendDefaultPii: true,
    });
});

