import {GemBotGiveawayInfoResponse as _GemBotGiveawayInfoResponse} from './tg-bot-models.d.ts';

export enum TgBotTxStatus {
    NotFound = 0, // Transaction was not found.
    New = 1, // Transaction is just created and has not been processed yet.
    Processing = 2, // Transaction is currently being processed.
    Failed = 3, // Transaction processing has failed.
    Executed = 4, // Transaction has been successfully executed.
}

export type TgBotGiveawayMemberStatus = 'candidate' | 'member' | 'member-ref' | 'claimer' | 'winner';

export interface GemBotGiveawayInfoResponse extends _GemBotGiveawayInfoResponse {
    memberStatus: TgBotGiveawayMemberStatus
}
